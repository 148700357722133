export const teamData = [
    {
        organization: "Rutgers University",
        directory: "profile_images/rutgers/",
        profiles: [
            {
                image: "desheng_zhang.webp",
                name: "Desheng Zhang",
                role: "Lead PI",
                department: "Computer Science",
                url: "https://www.cs.rutgers.edu/~dz220/",
            },
            {
                image: "suzanne_piotrowski.webp",
                name: "Suzanne Piotrowski",
                role: "Co-PI",
                department: "Public Affairs and Administration",
                url: "https://spaa.newark.rutgers.edu/suzanne-piotrowski",
            },
            {
                image: "jing_jin.webp",
                name: "Jing (Peter) Jin",
                role: "Co-PI",
                department: "Civil and Environmental Engineering",
                url: "https://cee.rutgers.edu/fac/jing-peter-jin",
            },
            {
                image: "gregory_porumbescu.webp",
                name: "Gregory Porumbescu",
                role: "Co-PI",
                department: "Public Affairs and Administration",
                url: "https://spaa.newark.rutgers.edu/gregory-porumbescu",
            },
            {
                image: "shuxin_zhong.webp",
                name: "Shuxin Zhong",
                role: "PhD Student",
                department: "Computer Science",
                url: null,
            },
            {
                image: "guang_yang.webp",
                name: "Guang Yang",
                role: "PhD Student",
                department: "Computer Science",
                url: null,
            },
            {
                image: "kayla_schwoerer.webp",
                name: "Kayla Schwoerer",
                role: "PhD Student",
                department: "Public Affairs and Administration",
                url: null,
            },
            {
                image: null,
                name: "Kasny Damas",
                role: "PhD Student",
                department: "Public Affairs and Administration",
                url: null,
            },
            {
                image: null,
                name: "Rahul Patel",
                role: "Undergrad",
                department: "Computer Science",
                url: null,
            },
            {
                image: null,
                name: "Ryhan Moghe",
                role: "Undergrad",
                department: "Computer Science",
                url: null,
            },
        ],
    },
    {
        organization: "University of Virginia",
        directory: "profile_images/u_virginia/",
        profiles: [
            {
                image: "john_a_stankovic.webp",
                name: "John A. Stankovic",
                role: "Co-PI",
                department: "Computer Science",
                url: "https://engineering.virginia.edu/faculty/john-stankovic",
            },
            {
                image: "meiyi_ma.webp",
                name: "Meiyi Ma",
                role: "PhD Student",
                department: "Computer Science",
                url: "http://www.cs.virginia.edu/~mm5tk/",
            },
            {
                image: null,
                name: "Sam Sun",
                role: "PhD Student",
                department: "Computer Science",
                url: null,
            },
        ],
    },
    {
        organization: "Stony Brook University",
        directory: "profile_images/stony_brook/",
        profiles: [
            {
                image: "shan_lin.webp",
                name: "Shan Lin",
                role: "Co-PI",
                department: "Electrical and Computer Engineering",
                url: "http://www.ece.sunysb.edu/~slin/",
            },
            {
                image: "yukun_yuan.webp",
                name: "Yukun Yuan",
                role: "PhD Student",
                department: "Electrical and Computer Engineering ",
                url: "https://www.yukunyuan.org",
            },
        ],
    },
    {
        organization: "University of Connecticut",
        directory: "profile_images/u_connecticut/",
        profiles: [
            {
                image: "fei_miao.webp",
                name: "Fei Miao",
                role: "Co-PI",
                department: "Computer Science and Engineering",
                url: "http://feimiao.org/",
            },
            {
                image: "sihong_he.webp",
                name: "Sihong He",
                role: "PhD Student",
                department: "Computer Science and Engineering",
                url: null,
            },{
                image: "songyang_han.webp",
                name: "Songyang Han",
                role: "PhD Student",
                department: "Computer Science and Engineering",
                url: null,
            },{
                image: "pepin _lynnn.webp",
                name: "Pepin Lynnn",
                role: "PhD Student",
                department: "Computer Science and Engineering",
                url: null,
            },
        ],
    },
    {
        organization: "City of Newark",
        directory: "profile_images/newark_city/",
        profiles: [
            {
                image: "shoshanna_page.webp",
                name: "Shoshanna Page",
                role: "Senior Policy Advisor",
                department: "",
                url: null,
            },
            {
                image: null,
                name: "Sherronda Carroll",
                role: "Newark IT Manager",
                department: "",
                url: null,
            },{
                image: "anthony_avent.webp",
                name: "Anthony Avent",
                role: "Technical Operations Officer",
                department: "",
                url: null,
            },{
                image: "julio_roman.webp",
                name: "Julio Roman",
                role: "Public Safety IT Director",
                department: "",
                url: null,
            },{
                image: null,
                name: "Luis Figueroa",
                role: "Sr. Traffic Maintenance Officer",
                department: "",
                url: null,
            },
        ],
    },
    {
        organization: "Industry and Non-Profits",
        directory: "profile_images/industry/",
        profiles: [
            {
                image: "yuanchao_shu.webp",
                name: "Yuanchao Shu",
                role: "PhD, Senior Researcher",
                department: "Microsoft Research",
                url: null,
            },
            {
                image: "jorge_santos.webp",
                name: "Jorge Santos",
                role: "Senior Vice President",
                department: "Newark Alliance",
                url: null,
            },{
                image: null,
                name: "Coram Rimes",
                role: "CEO",
                department: "Black Data Processing Associates (BDPA)",
                url: null,
            },{
                image: "carlos_valentin.webp",
                name: "Carlos Valentin",
                role: "Executive Director",
                department: "ASPIRA Inc",
                url: null,
            },
        ],
    },
        {
        organization: "Collaborators",
        directory: "profile_images/collaborators/",
        profiles: [
            {
                image: "yu_yang.webp",
                name: "Yu Yang",
                role: "Assistant Professor",
                department: "Computer Science and Engineering",
                url: "https://www.yyang.site/",
            },
            {
                image: "guang_wang.webp",
                name: "Guang Wang",
                role: "Assistant Professor",
                department: "Computer Science",
                url: "https://guangwang.me",
            },{
                image: "yi_ding.webp",
                name: "Yi Ding",
                role: "Postdoctoral Associate",
                department: "Sociotechnical Systems Research Center",
                url: "https://yi-ding.me",
            },
        ],
    }
];
